import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { usersSlice } from "../app/modules/ECommerce/_redux/users/usersSlice";
import { agencesSlice } from "../app/modules/ECommerce/_redux/agences/agencesSlice";
import { commandesSlice } from "../app/modules/ECommerce/_redux/commandes/commandesSlice";
import { voyagesSlice } from "../app/modules/ECommerce/_redux/voyages/voyagesSlice";
import { colisSlice } from "../app/modules/ECommerce/_redux/colis/colisSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  users : usersSlice.reducer,
  agences : agencesSlice.reducer,
  commandes : commandesSlice.reducer,
  voyages : voyagesSlice.reducer,
  colis :colisSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
