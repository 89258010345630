import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";

const initialValues = {
  fullname: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    username: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      register(values.email, values.fullname, values.username, values.password)
        .then(({ data: { accessToken } }) => {
          props.register(accessToken);
          disableLoading();
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <h1>Politique de confidentialité</h1> <br />
      <h2>ARTICLE 1 : Objet </h2> <br />
      Chez Evvent's, le respect de votre privée et de votre confidentialité est
      et sera toujours notre priorité absolue.  Nous concevons et développons
      nos produits de façon à ce que vous puissiez leur faire pleinement
      confiance et que votre seule et unique préoccupation reste de Booster
      votre vie sociale grâce à Evvent's. Cette politique de confidentialité
      (ci-après « PDC ») a vocation à vous informer de l’utilisation de vos
      données à caractère personnel par Evvent's.
      <br />
      <h2>ARTICLE 2 : Généralités </h2> <br />
      L’édition de la Plateforme Evvent's est assurée par Ayoub Stati, fondateur
      de cette plateforme innovante et développé par Chloé Modolo.
      <br />
      <h2>ARTICLE 3 : Informations recueillies </h2> <br />
      Les informations que nous recueillons sont soit le fait de vos
      transmissions à notre égard, soit récupérées automatiquement lors de votre
      usage de nos services. Il y a deux types de données que vous nous
      transmettez : les données publiques qui sont visibles par les autres
      Membres de Evvent's, les données privées qui ne sont visibles que par
      Evvent's et ses partenaires français ou mondiaux. Ces données peuvent être
      soit obligatoires pour utiliser nos Services, soit facultatives et en ce
      sens, il s’agit de votre choix personnel de les communiquer ou non.   3.1
      : Données relatives à l’inscription Lors de l’inscription, il est demandé
      un certain nombre d’informations facultatives ou obligatoires nécessaires
      à l’utilisation de nos Services. Les données obligatoires publiques sont
      votre genre, votre âge, votre prénom, votre ville, vos loisirs préférés,
      votre photo ou encore une description de vous (Evvent's recommande de ne
      saisir dans cette dernière que des données jugées non sensibles). Les
      données sont qualifiées juridiquement de « sensibles » en vertu du
      Règlement général pour la protection des données (« RGPD »). Il s’agit
      notamment, des données relatives à l’origine raciale ou ethnique, aux
      opinions politiques, aux convictions religieuses ou philosophiques ou à
      l’appartenance syndicale, ainsi qu’à la santé, à la vie sexuelle ou
      l’orientation sexuelle (« Données sensibles »). Les données obligatoires
      privées sont votre nom et votre adresse email. La seule donnée facultative
      est votre numéro de téléphone est facultatif et il s’agit d’une donnée
      privée pour sécuriser votre compte avec notre procédure 2FA détaillée dans
      les Conditions Générales d’Utilisations. Données qu’il est possible de
      remplir de manière facultative dans votre profil Certaines informations de
      votre profil sont pré remplies par notre système avec l’information «
      C’est secret ». Ces informations, qui sont modifiables si vous le
      souhaitez, sont votre profession, votre parcours scolaire, votre
      université, vos préférences en termes de tabac, d’alcool, de diététiques,
      votre situation parentale et les langues que vous comprenez. Données
      relatives aux transmissions avec Evvent's Evvent's propose sur sa
      plateforme aux utilisateurs d'utiliser leur caméra afin de créer leur
      photo de profil ou les photos événements. Lors de vos interactions avec
      Evvent's à travers la fonction « Nous contacter ». Ces données sont
      notamment la date et l’heure de la prise de contact et toutes les
      informations nécessaires à identifier les personnes (témoignages,
      satisfaction, suggestions…). Ces données sont essentielles à la gestion de
      l’interaction afin que Evvent's puisse traiter la situation nécessitant la
      prise de contact avec le Membre. En acceptant d’utiliser la Plateforme
      Evvent's, le Membre accepte de fait et sans réserve la collecte de ces
      données et le traitement réalisé par Evvent's dans la présente PDC. 3.2 :
      Données automatiquement recueillies Certaines données sont automatiquement
      recueillies lors de votre utilisation de la Plateforme Evvent's. Ces
      données sont de manière non exhaustive votre adresse IP (privée), votre
      dernière date et heure de connexion (privée), votre date d’inscription à
      la Plateforme (publique), votre position géographique avec votre
      consentement initial (privée) ou encore vos interactions avec les
      différents membres de Evvent's et la Société (privée).  Nota bene : Vous
      pouvez désactiver la géolocalisation à tout moment sur votre device et
      ainsi retirer votre consentement à Evvent's pour vous géolocaliser. Dans
      ce cas, Evvent's utilisera votre ville saisie lors de votre inscription
      pour vous proposer les activités ou encore les membres qui vous entourent.
      Les interactions comprennent les messages publics et privés envoyés et
      reçus par un Membre qui sont stockés sur nos serveurs sécurisés et qui
      permettent à un Membre de retrouver ces informations sur différents
      supports (téléphones, tablettes…). Evvent's ne consulte pas ces données
      mais a la possibilité technique de le faire si jamais cela s’avérait
      nécessaire. Ces échanges ne sont jamais communiqués à une tierce personne
      qui n’y a pas prit part, sauf sur requête judiciaire et/ou conformément
      aux dispositions législatives ou règlementaires applicables. Evvent's
      collecte également les préférences de recherche de ses Membres (privée)
      pour leur offrir l’expérience utilisateur la plus aboutie et ainsi leur
      proposer en permanence des Activités ou des Membres qui leur
      correspondent. Enfin Evvent's collecte toutes les données techniques
      nécessaires à offrir un bon fonctionnement et une bonne analyse de la
      Plateforme. Ces données qui sont privées sont : l’adresse IP, le modèle et
      les données techniques du device utilisé, la version de l’application en
      cours, l’ID publicitaire du Membre ou toute autre donnée technique émanant
      du device et du réseau utilisé. Pour ce faire, le Membre autorise Evvent's
      à utiliser les Cookies nécessaires à la réalisation de la collecte
      susvisée. La collecte de ces informations est obligatoire et essentielle à
      Evvent's pour vous permettre d’utiliser nos Services. En acceptant
      d’utiliser la Plateforme Evvent's, le Membre accepte de fait et sans
      réserve la collecte de ces données et le traitement réalisé par Evvent's
      dans la présente PDC ainsi que la Politique de gestion des cookies de
      Evvent's. Nota bene :  Evvent's ne collecte et ne traite aucune donnée
      bancaire. Pour Android, le paiement est traité et géré par la société
      Stripe, leader mondial du paiement en ligne, qui gère de manière cryptée
      la communication avec les banques. Pour iOS, le paiement est traité et
      géré par la société Apple Inc directement via le compte iTunes du Membre.
      Evvent's n’a aucun contrôle sur le paiement, sur le transfert de fond ou
      encore sur le changement de devises et les frais inhérents à ces
      opérations. Evvent's n’a aucun accès et ne collecte aucune donnée de
      paiement. L’intégralité des opérations de paiements sont gérées par la
      société Stripe ou par Apple qui sont par conséquent seuls responsables de
      l’intégralité des opérations de paiements. Pour plus d’informations sur la
      gestion par la société Stripe des opérations de paiements, les
      Utilisateurs de Evvent's peuvent se rendre sur ce lien :
      https://stripe.com/fr/privacy A défaut d’éléments contraires apportes par
      le Membre abonné, les registres informatisés, conservés dans les systèmes
      informatiques de Evvent's et de ses partenaires dans des conditions
      raisonnables de sécurité, seront considérés comme les preuves des
      communications, des commandes, des validations et des paiements intervenus
      entre le Membre et Evvent's. Ces informations feront foi sauf à̀ en
      apporter la preuve écrite et contraire par le Membre. L’archivage de ces
      éléments est effectué sur un support fiable et durable de manière à
      correspondre à̀ une copie fidèle et durable conformément à l’article 1348
      du code civil.
      <br />
      <h2>ARTICLE 4 : Ce que nous faisons de vos données ? </h2>
      <br />
      Vos données sont collectées et utilisées car elles sont nécessaires au bon
      fonctionnement de votre compte de Membre au sein de la Plateforme
      conformément à cette PDC et aux Conditions Générales d’Utilisation, ce qui
      permet l’exécution du contrat entre vous et Evvent's. Elles sont utilisées
      notamment : pour créer et gérer votre compte de Membre, pour vous apporter
      le soutien technique au travers de notre support, pour gérer le stockage
      des interactions entre Membres, pour vous faire parvenir des notifications
      Vos données nous permettent de vous géolocaliser avec votre consentement,
      ce qui nous permet de personnaliser votre expérience utilisateur en vous
      proposant les Activités et les Membres qui se trouve aux alentours de
      vous. Nota bene : Votre géolocalisation est privée et n’est accessible à
      aucune tierce personne et vous pouvez désactiver à tout moment cette
      option sur votre device. Vos données sont utilisées pour vous proposer du
      contenu marketing et publicitaire personnalisé qui représente un réel
      intérêt pour vous (diffusion par bannières, par messages push, par
      email…). Ces éléments marketing sont ciblés en fonction de votre
      géolocalisation, de votre genre, de votre âge et de vos préférences. Nota
      bene : Vous pouvez à tout moment vous désabonner de la Newsletter par
      email en cliquant en bas de page de cet email reçu sur le lien adéquat.
      Vous pouvez également vous opposer à l’usage et au partage de vos données
      en nous contactant directement par email sur support@gmail.com et en nous
      explicitant clairement votre souhait. En fonction de votre requête, nous
      vous préciserons si nous pouvons continuer notre collaboration. En effet,
      l’exécution du contrat qui vous lie à Evvent's nécessite l’acceptation de
      certains éléments marketing essentiels à l’activité économique de
      Evvent's. Vos données sont utilisées pour analyser l’usage que vous faites
      de notre Plateforme soit au travers d’outils techniques qui génèrent des
      statistiques (suite aux signalements, analyse de faux profils ou à des
      usages non conformes à nos conditions générales d’utilisation…), soit au
      travers d’enquêtes de satisfaction ou toute autre méthode d’analyse
      comportementale permettant de rendre votre expérience toujours plus fluide
      et intuitive. Nota bene : L’objectif de cette analyse de données est la
      fourniture de services et fonctionnalités de qualités ; la proposition
      d’offres et contenus pertinents ainsi que la sécurisation des données et
      la lutte contre les activités illégales, SPAM ou dérangeantes. Vos données
      sont utilisées pour vous protéger en détectant des comportements
      frauduleux qui pourraient nuire à la sécurité de la Plateforme. Evvent's a
      une obligation de moyen de tout mettre en œuvre pour sécuriser au maximum
      ses Services donc si vous souhaitiez appliquer votre droit de vous opposer
      à l’usage de certaines de ces données tel que vous l’autorise la
      législation, il se peut que nous soyons contraints de rompre le contrat
      qui nous lie et de devoir stopper notre collaboration pour des motifs
      légitimes et impératifs. Nota bene : Comme stipulé dans nos Conditions
      Générales d’Utilisation et de Vente, Evvent's, en tant qu’hébergeur de
      données, a l’obligation de retirer les contenus illicites dès qu’il en a
      connaissance (article 6 de la loi du 21 juin 2004 pour la confiance dans
      l’économie numérique) et a une obligation de répondre aux demandes
      d’exercice de droits des Membres sur leurs données personnelles (article
      12 à 22 du RGPD).
      <br />
      <h2>ARTICLE 5 : Comment sont partagés vos données ? </h2>
      <br />
      Vos données sont accessibles à seulement certains salariés habilités à
      traiter vos données personnelles ainsi qu’à certains de nos partenaires
      (dans et en dehors de l’Union Européenne) certifiés par Evvent's qui
      respectent notre Politique de confidentialité soit par voie légale soit
      par contractualisation de la collaboration. Les prestataires qui
      participent à l’exploitation, l’amélioration et la sécurisation de notre
      Plateforme sont notamment ceux qui peuvent se retrouver à gérer :
      l’hébergement des données, les outils permettant le bon fonctionnement de
      la Plateforme, les études et analyses statistiques de la Plateforme,  la
      détection et la gestion des comportements douteux sur la Plateforme, 
      l’assistance aux Membres, le marketing et la publicité sur et en dehors de
      notre Plateforme, Nous utilisons notamment des cookies pour communiquer
      vos données personnelles à nos prestataires (cf. Politique de gestion des
      cookies) En cas de changement de contrôle (fusion, acquisition, cession…)
      de notre Société, nous pourrions être amenés à communiquer vos données
      personnelles à la nouvelle structure qui serait alors la nouvelle
      propriétaire de vos données. Vous seriez alors informés de l’opération
      pour vous opposer à la communication de vos données. Nota bene : Vos
      données peuvent également être communiquées aux autorités judiciaires si
      tous les éléments juridiques sont réunis et que cela s’impose légalement à
      la Plateforme.
      <br />
      <h2>ARTICLE 6 : Comment gérer vos droits sur vos données ? </h2>
      <br />
      Conformément à la loi n° 78-17 du 6 janvier 1978, dite loi Informatique et
      Libertés et au RGPD, chaque Membre dispose d’un droit d’accès, de
      rectification et, le cas échéant d’un droit à la portabilité et à
      l’effacement sur ses données personnelles, ainsi que d’opposition au
      traitement ou à sa limitation et du droit de définir des directives
      relatives au sort de ses données personnelles après son décès.  Evvent's
      s’engage à respecter la protection des données personnelles et à traiter
      ces demandes dans les meilleurs délais pour satisfaire au maximum les
      demandes de nos Membres. Nota bene : En cas de désaccord avec notre
      Société, vous pouvez émettre une réclamation auprès de l’autorité de
      contrôle nationale compétente en matière de protection des données.
      Explication de vos droits susvisés : Le droit d’accès autorise le Membre à
      interroger Evvent's en vue d’obtenir la communication des données le
      concernant sous une forme accessible sur le fondement de l’article 39 de
      la loi Informatique et Libertés et de l’article 15 du RGPD. Votre droit
      d’accès ne doit pas porter atteinte à la vie privée des tiers, c’est
      pourquoi vous n’aurez accès qu’à vos données personnelles et non pas à
      celles de tiers (par exemple, vous ne pourrez avoir communication que des
      messages que vous avez envoyés et non pas de ceux que vous avez reçus). Le
      droit de rectification confère au Membre le droit d’exiger de Evvent's que
      soient rectifiées, complétées, mises à jour ou effacées les données à
      caractère personnel le concernant, qui sont inexactes, incomplètes,
      équivoques, périmées ou dont l’utilisation, la communication ou la
      conservation est interdite sur le fondement de l’article 40 de la loi
      Informatique et Libertés et de l’article 16 du RGPD. Vous pouvez modifier
      ou rectifier certaines données directement depuis l’Application. Il vous
      suffit de vous rendre sur votre Profil et modifier directement les
      sections « Mes Photos », « À propos de moi » et « En savoir plus ». Il
      vous suffit de vous rendre dans le Menu de l’Application dans l’onglet
      Paramètre pour modifier directement les section « Prénom », « Nom », «
      Ville », « Date de naissance », « Email » et « Téléphone ». Le droit à la
      portabilité confère au Membre le droit de recevoir les données à caractère
      personnel qu’il a fournies à Evvent's, dans un format structuré,
      couramment utilisé et lisible par machine et de les transmettre à un autre
      responsable de traitement, sur le fondement de l’article 20 du RGPD.  Le
      droit d’opposition confère au Membre le droit de s’opposer sans frais et
      de manière discrétionnaire, à ce que ses données soient utilisées par
      Evvent's à des fins de prospection, notamment commerciale et aux
      traitements fondés sur l’intérêt légitime de Evvent's, sur le fondement de
      l’article 38 de la loi Informatique et Libertés et de l’article 21 du
      RGPD. Pour les traitements fondés sur l’intérêt légitime, Evvent's
      conserve la possibilité de justifier de motifs légitimes et impérieux pour
      continuer le traitement.  Le droit à l’effacement confère au Membre le
      droit d’exiger l’effacement sans frais de manière discrétionnaire des
      données communiquées à Evvent's, dans la limite des droits fondés sur
      l’article 17 du RGPD. Vous pouvez supprimer votre Compte en utilisant la
      fonctionnalité correspondante directement sur l’Application. Nous vous
      informons que l’ensemble de vos données seront supprimées de l’application
      Evvent's. Cependant, Evvent's, en tant qu’hébergeur, a l’obligation légale
      de conserver vos données personnelles pendant un an au titre de l’article
      6-II) de la loi n°2004-575 du 21 juin 2004 pour la confiance dans
      l’économie numérique. Vos données seront conservées dans une base
      d’archives séparée de nos bases de production et seulement aux fins de
      répondre à une réquisition judiciaire. Elles seront définitivement
      supprimées à l’expiration du délai d’un an. Le droit à la limitation
      confère au Membre le droit de demander la limitation du traitement de ses
      données par Evvent's dans les conditions de l’article 18 du RGPD. Le droit
      pour le Membre de définir ses directives relatives à la conservation, à
      l’effacement et à la communication de ses données à caractère personnel
      après son décès conformément à l’article 40-1 de la loi Informatique et
      Libertés
      <br />
      <h2>ARTICLE 7 : Durée de conservation de vos données ? </h2>
      Vos données sont stockées par Evvent's durant toute la période où le
      Contrat représenté par les CGU vous lie à la Société. Vos données seront
      définitivement supprimées un an après la fin du Contrat susvisé, que cela
      intervienne suite à une suppression définitive du Compte par le Membre ou
      alors suite à une suppression du Compte du Membre par la Société. Lors de
      la suppression du Compte d’un Membre : Le profil du Membre sera rendu
      invisible aux autres Membres. Les données du Membre seront conservées
      pendant le délai de 1 an à compter de cette date au regard notamment des
      obligations légales qui pèsent sur Evvent's puis supprimées définitivement
      à l’issue de ce délai. Les avantages disponibles (par exemple un Pack
      payant en cours de validité) sur le Compte du Membre au jour de la
      suppression de son compte seront définitivement perdus, sans possibilité
      de remboursement. Le Membre ne pourra pas réactiver son ancien Compte et
      son adresse email utilisée pour ce dernier ne sera plus utilisable sur la
      Plateforme durant l’année de conservation de ses données. Rappel,
      conformément à son statut d’hébergeur, Evvent's est tenu d’une obligation
      légale de conserver certaines données à caractère personnel des Membres
      pendant une durée d’un an sur le fondement de l’article 6, II de la loi
      pour la confiance dans l’économie numérique du 21 juin 2004. Evvent's a
      toujours été et sera toujours à l’écoute de ses Membres car c’est grâce à
      vous que nous vivons une aventure extraordinaire. Merci pour votre
      lecture.
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
